import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { OfficialMembers } from '@/services/OfficialMembersService'
import { APIURL } from '@/components/Utility/APIBase'

@Options({
  props: {
    selectedSlugType: String
  }
})
export default class OfficialMembersComponent extends Vue {
  selectedSlugType!: string
  APIURL = APIURL
  OfficialMembersData: any = null

  public async getOfficialMembersData () : Promise<void> {
    try {
      const OfficialMembersRes: any = await OfficialMembers.OfficialMembersService.getOfficialMembers()
      this.OfficialMembersData = OfficialMembersRes.data
    } catch (error) {
      console.log('Error', error)
    }
  }

  get officialMembers () {
    if (this.selectedSlugType === 'landstraenere-og-elitechef' && this.landstraeners) {
      return this.landstraeners
    } else if (this.selectedSlugType === 'bestyrelse' && this.bestyrelse) {
      return this.bestyrelse
    } else if (this.selectedSlugType === 'eliteudvalg' && this.eliteudvalg) {
      return this.eliteudvalg
    } else if (this.selectedSlugType === 'ungdomsudvalg' && this.ungdomsudvalg) {
      return this.ungdomsudvalg
    } else if (this.selectedSlugType === 'staevneudvalg' && this.staevneudvalg) {
      return this.staevneudvalg
    } else if (this.selectedSlugType === 'turneringsudvalg' && this.turneringsudvalg) {
      return this.turneringsudvalg
    } else if (this.selectedSlugType === 'paradart-udvalget' && this.paradartudvalget) {
      return this.paradartudvalget
    } else if (this.selectedSlugType === 'juniorudvalg' && this.juniorudvalg) {
      return this.juniorudvalg
    } else {
      return []
    }
  }

  // National coaches and Elite manager
  get landstraeners () {
    const landstraenersData = (this as any).OfficialMembersData?.filter((item: any) =>
      item.official_landstraener && item
    )
    const order = ['herrer', 'damer', 'junior', 'paradart']
    if (landstraenersData) {
      landstraenersData.sort((a: any, b: any) => {
        return order.indexOf(a.official_landstraenertype) - order.indexOf(b.official_landstraenertype)
      })
    }
    return landstraenersData
  }

  // Board of Directors
  get bestyrelse () {
    const bestyrelseData = (this as any).OfficialMembersData?.filter((item: any) =>
      item.official_bestyrelse && item
    )
    const order = ['formand', 'naestformand', 'oekonomiansvarlig']
    if (bestyrelseData) {
      bestyrelseData.sort((a: any, b: any) => {
        if (!a.official_type) return 1
        if (!b.official_type) return -1
        return order.indexOf(a.official_type) - order.indexOf(b.official_type)
      })
    }
    return bestyrelseData
  }

  // Elite committee
  get eliteudvalg () {
    const eliteudvalgData = (this as any).OfficialMembersData?.filter((item: any) =>
      item.official_eliteudvalg && item
    )
    const order = ['formand', 'raadgiver', 'ungdomsrepraesentant']
    if (eliteudvalgData) {
      eliteudvalgData.sort((a: any, b: any) => {
        if (!a.official_udvalgtype) return 1
        if (!b.official_udvalgtype) return -1
        return order.indexOf(a.official_udvalgtype) - order.indexOf(b.official_udvalgtype)
      })
    }
    return eliteudvalgData
  }

  // Youth committee
  get ungdomsudvalg () {
    const ungdomsudvalgData = (this as any).OfficialMembersData?.filter((item: any) =>
      item.official_ungdomsudvalg && item
    )
    const order = ['formand', 'raadgiver', 'ungdomsrepraesentant']
    if (ungdomsudvalgData) {
      ungdomsudvalgData.sort((a: any, b: any) => {
        if (!a.official_udvalgtype) return 1
        if (!b.official_udvalgtype) return -1
        return order.indexOf(a.official_udvalgtype) - order.indexOf(b.official_udvalgtype)
      })
    }
    return ungdomsudvalgData
  }

  // Convention committee
  get staevneudvalg () {
    const staevneudvalgData = (this as any).OfficialMembersData?.filter((item: any) =>
      item.official_staevneudvalg && item
    )
    const order = ['formand', 'raadgiver', 'ungdomsrepraesentant']
    if (staevneudvalgData) {
      staevneudvalgData.sort((a: any, b: any) => {
        if (!a.official_udvalgtype) return 1
        if (!b.official_udvalgtype) return -1
        return order.indexOf(a.official_udvalgtype) - order.indexOf(b.official_udvalgtype)
      })
    }
    return staevneudvalgData
  }

  // Tournament committee
  get turneringsudvalg () {
    const turneringsudvalgData = (this as any).OfficialMembersData?.filter((item: any) =>
      item.official_turneringsudvalg && item
    )
    const order = ['formand', 'raadgiver', 'ungdomsrepraesentant']
    if (turneringsudvalgData) {
      turneringsudvalgData.sort((a: any, b: any) => {
        if (!a.official_udvalgtype) return 1
        if (!b.official_udvalgtype) return -1
        return order.indexOf(a.official_udvalgtype) - order.indexOf(b.official_udvalgtype)
      })
    }
    return turneringsudvalgData
  }

  // Paradart committee
  get paradartudvalget () {
    const paradartudvalgetData = (this as any).OfficialMembersData?.filter((item: any) =>
      item.official_paradartudvalg && item
    )
    const order = ['formand', 'raadgiver', 'ungdomsrepraesentant']
    if (paradartudvalgetData) {
      paradartudvalgetData.sort((a: any, b: any) => {
        if (!a.official_udvalgtype) return 1
        if (!b.official_udvalgtype) return -1
        return order.indexOf(a.official_udvalgtype) - order.indexOf(b.official_udvalgtype)
      })
    }
    return paradartudvalgetData
  }

  get juniorudvalg () {
    const juniorudvalgData = (this as any).OfficialMembersData?.filter((item: any) =>
      item.official_juniorudvalg &&
      item
    )
    const order = ['formand', 'raadgiver', 'ungdomsrepraesentant']
    if (juniorudvalgData) {
      juniorudvalgData.sort((a: any, b: any) => {
        if (!a.official_udvalgtype) return 1
        if (!b.official_udvalgtype) return -1
        return order.indexOf(a.official_udvalgtype) - order.indexOf(b.official_udvalgtype)
      })
    }
    return juniorudvalgData
  }

  public makeOfficialTypeStr (typeStr: string) : string {
    return typeStr.charAt(0).toUpperCase() + typeStr.slice(1)
  }

  public replaceSpecialCharacters (str: string) : string {
    return str
      .replace(/ae/g, 'æ')
      .replace(/Oe/g, 'Ø')
      .replace(/oe/g, 'ø')
      .replace(/aa/g, 'å')
  }

  async mounted () : Promise<void> {
    this.getOfficialMembersData()
  }
}
